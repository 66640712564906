const actions = {
  GET_CUSTOMER: 'GET_CUSTOMER',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  UPDATE_CUSTOMER_SAGA: 'UPDATE_CUSTOMER_SAGA',
  SELECT_CURRENT_CUSTOMER: 'SELECT_CURRENT_CUSTOMER',
  TOGGLE_VIEW: 'CUSTOMER_TOGGLE_VIEW',
  UPDATE_EDIT_CUSTOMER: 'CUSTOMER_UPDATE_EDIT_CUSTOMER',
  initData: () => ({ type: actions.GET_CUSTOMER }),
  deleteCustomer: (selected) => {
    return (dispatch, getState) => {
      const customers = getState().Customers.customers;
      const newCustomers = [];
      customers.forEach((customer) => {
        const selectedIndex = selected.indexOf(customer.key);
        if (selectedIndex === -1) {
          newCustomers.push(customer);
        }
      });
      dispatch({
        type: actions.UPDATE_CUSTOMER_SAGA,
        customers: newCustomers,
      });
    };
  },
  updateCustomer: (customer) => {
    return (dispatch, getState) => {
      const customers = getState().Customers.customers;
      const index = customers.map((inv) => inv.id).indexOf(customer.id);
      if (index === -1) {
        customers.push(customer);
      } else {
        customers[index] = customer;
      }
      dispatch({
        type: actions.UPDATE_CUSTOMER_SAGA,
        customers,
        customer,
      });
    };
  },
  selectCurrentCustomer: (id) => ({ type: actions.SELECT_CURRENT_CUSTOMER, id }),
  toggleView: (view) => ({ type: actions.TOGGLE_VIEW, view }),
  editCustomer: (customer) => ({ type: actions.UPDATE_EDIT_CUSTOMER, customer }),
};
export default actions;
