import { Map } from 'immutable';
import moment from 'moment';
import * as xlsx from 'xlsx';
import { USER_TYPE } from '../../containers/pre-registers/Customer/constants';
moment.locale('pt-br'); // Defina o idioma Moment.js globalmente

export function clearToken() {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('selectedCustomer');
}

export function getToken() {
  try {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const selectedCustomer = localStorage.getItem('selectedCustomer');
    return new Map({ token, user: JSON.parse(user), selectedCustomer: JSON.parse(selectedCustomer) });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

// REVIEW - Where is it used?
export function arrayEqual(array1, array2) {
  return array1.sort().toString() === array2.sort().toString();
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? 's' : '';
  };
  const number = (num) => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}

export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

export async function exportToExcel({ columnDefinitions = [], exportedData = [], includeColumns = [], filename = '' }) {
  const colDefFiltered = columnDefinitions.filter((value) => value.title !== 'Ações').map(({ title, dataIndex }) => ({ title, dataIndex }));

  for (const column of includeColumns) {
    const { title, dataIndex } = column;
    colDefFiltered.push({ title, dataIndex });
  }

  const headers = colDefFiltered.map(({ title }) => title);

  const formattedData = exportedData.map((dataItem) => {
    const newDataItem = {};
    for (const key in dataItem) {
      if (Object.hasOwnProperty.call(dataItem, key)) {
        colDefFiltered.forEach((cd) => {
          if (/....-..-..T..:..:..\....Z/.test(dataItem[cd.dataIndex])) {
            newDataItem[cd.dataIndex] = moment(dataItem[cd.dataIndex]).format('DD/MM/YYYY HH:mm:ss');
          } else if (cd.dataIndex === 'plan') {
            newDataItem[cd.dataIndex] = dataItem['plan'].planType;
          } else if (cd.dataIndex === 'address') {
            // Handle 'plan' and 'address' fields if needed in the future
          } else if (cd.dataIndex === 'userType') {
            newDataItem[cd.dataIndex] = USER_TYPE[dataItem[cd.dataIndex]];
          } else if (cd.dataIndex === 'active') {
            newDataItem[cd.dataIndex] = dataItem[cd.dataIndex] ? 'Ativo' : 'Inativo';
          } else {
            newDataItem[cd.dataIndex] = dataItem[cd.dataIndex];
          }
        });
      }
    }

    return newDataItem;
  });

  const worksheet = xlsx.utils.json_to_sheet(formattedData);
  const minimumColumnWidth = 10;
  const columnWidths = formattedData.reduce((acc, row) => {
    Object.keys(row).forEach((key) => {
      const columnLength = row[key] ? String(row[key]).length : 0;
      acc[key] = Math.max(acc[key] || 0, columnLength);
    });
    return acc;
  }, {});

  Object.keys(columnWidths).forEach((key) => {
    const calculatedWidth = Math.max(columnWidths[key], minimumColumnWidth);
    columnWidths[key] = calculatedWidth;
  });

  worksheet['!cols'] = Object.keys(columnWidths).map((key) => ({
    wch: columnWidths[key],
  }));

  const workbook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(workbook, worksheet, 'Dates');
  xlsx.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
  const timestamp = new Date().toLocaleDateString();
  const excelFilename = `${filename || 'registro'}-${timestamp}.xlsx`;
  await xlsx.writeFile(workbook, excelFilename);
}

export function checkIfBase64IsAValidImage(base64) {
  if (!base64) {
    return false;
  }
  const base64Regex = /^data:image\/([a-zA-Z]*);base64,([^\"]*)$/;
  return base64Regex.test(base64);
}
