const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  UPDATE_USER: 'UPDATE_USER',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOADING: 'LOADING',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (payload) => ({
    type: actions.LOGIN_REQUEST,
    payload: payload,
  }),
  updateUser: (payload) => ({
    type: actions.UPDATE_USER,
    payload: payload,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  loading: () => ({
    type: actions.LOADING,
  }),
};
export default actions;
