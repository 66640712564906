const localDataName = 'mateCustomer';
const orderStatusOptions = ['Pending', 'Shipped', 'Delivered'];

const fakedata = [
  {
    key: 1,
    name: 'John Doe',
    email: 'johndoe@example.com',
    cpf: '123.456.789-01',
    house: '123 Main Street',
    phone: '555-1234',
    plan: 'Basic',
    type: 'Profissional',
  },
  {
    key: 2,
    name: 'Jane Smith',
    email: 'janesmith@example.com',
    cpf: '234.567.890-12',
    house: '456 Elm Street',
    phone: '555-5678',
    plan: 'Premium',
    type: 'Proprietario',
  },
  {
    key: 3,
    name: 'Robert Johnson',
    email: 'robertjohnson@example.com',
    cpf: '345.678.901-23',
    house: '789 Oak Avenue',
    phone: '555-9012',
    plan: 'Basic',
    type: 'Profissional',
  },
  {
    key: 4,
    name: 'Emily Davis',
    email: 'emilydavis@example.com',
    cpf: '456.789.012-34',
    house: '987 Pine Street',
    phone: '555-3456',
    plan: 'Premium',
    type: 'Proprietario',
  },
  {
    key: 5,
    name: 'Michael Brown',
    email: 'michaelbrown@example.com',
    cpf: '567.890.123-45',
    house: '654 Cedar Lane',
    phone: '555-7890',
    plan: 'Basic',
    type: 'Profissional',
  },
  {
    key: 6,
    name: 'Sophia Wilson',
    email: 'sophiawilson@example.com',
    cpf: '678.901.234-56',
    house: '321 Maple Drive',
    phone: '555-2345',
    plan: 'Premium',
    type: 'Proprietario',
  },
  {
    key: 7,
    name: 'Daniel Taylor',
    email: 'danieltaylor@example.com',
    cpf: '789.012.345-67',
    house: '1234 Oak Street',
    phone: '555-6789',
    plan: 'Basic',
    type: 'Profissional',
  },
  {
    key: 8,
    name: 'Olivia Miller',
    email: 'oliviamiller@example.com',
    cpf: '890.123.456-78',
    house: '4321 Elm Court',
    phone: '555-1234',
    plan: 'Premium',
    type: 'Proprietario',
  },
  {
    key: 9,
    name: 'William Anderson',
    email: 'williamanderson@example.com',
    cpf: '901.234.567-89',
    house: '5678 Pine Lane',
    phone: '555-5678',
    plan: 'Basic',
    type: 'Profissional',
  },
  {
    key: 10,
    name: 'Ava Garcia',
    email: 'avagarcia@example.com',
    cpf: '012.345.678-90',
    house: '8765 Cedar Avenue',
    phone: '555-9012',
    plan: 'Premium',
    type: 'Proprietario',
  },
];

const newCustomer = {
  orderStatus: 'Pending',
  orderDate: new Date().getTime(),
  currency: '$',
  billTo: '',
  billToAddress: '',
  billFrom: '',
  billFromAddress: '',
  inspectionList: [
    {
      key: 1,
      itemName: '',
      costs: 0,
      qty: 0,
      price: 0,
    },
  ],
  subTotal: 0,
  vatRate: 10,
  vatPrice: 0,
  totalCost: 0,
};
const createDemoData = () => {
  const localData = localStorage.getItem(localDataName);
  if (localData) {
    try {
      const inspections = JSON.parse(localData);
      if (inspections && inspections.length > 0) {
        return inspections;
      }
    } catch (e) {}
  }
  return fakedata;
};

export { fakedata, createDemoData, localDataName, newCustomer, orderStatusOptions };
