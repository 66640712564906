import { DeleteFilled, EditFilled, UserAddOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

export const NEW_BUTTON_TEXT = 'pages.register.customers.newCustomers';
export const NEW_BUTTON_PROFESSIONAL_TEXT = 'pages.register.customers.newProfessional';
export const NEW_BUTTON_USER_TEXT = 'pages.register.customers.newUser';

export const NO_CONTENT_TEXT = 'Não há clientes cadastrados';
export const NO_CONTENT_PROFESSIONAL_TEXT = 'Não há profissionais cadastrados.';
export const NO_CONTENT_USER_TEXT = 'Não há usuários cadastrados.';

export const PAGE_TITLE = 'pages.registers.customers.pageTitle';
export const PAGE_TITLE_PROFESSIONAL = 'pages.registers.customers.professionalPageTitle';
export const PAGE_TITLE_USER = 'pages.registers.customers.usersPageTitle';

export const CREATE_EDIT_PAGE_TITLE = (customerId, isAdmin, isProfessional) => {
  if (isAdmin) {
    return customerId !== 'new' ? 'sidebar.users.edit' : 'sidebar.users.new';
  } else if (isProfessional) {
    return customerId !== 'new' ? 'sidebar.customers.edit' : 'sidebar.customers.new';
  } else {
    return customerId !== 'new' ? 'sidebar.professional.edit' : 'sidebar.professional.new';
  }
};

export const USER_TYPE = {
  SUB_ADMIN: 'Sub Administrador',
  ADMIN: 'Administrador',
  PROFESSIONAL: 'Profissional',
  PROPRIETARY: 'Proprietário',
};

export const PLAN_TYPE = {
  FREE: 'Gratuito',
  MONTHLY: 'Mensal',
  QUATERLY: 'Semestral',
  YEARLY: 'Anual',
};

export const COLUMNS_DEFINITION = ({ onChangeStatus, matchParams, permission, unlinkUser }) => {
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      rowKey: 'name',
      width: '20%',
      render: (text) => <span>{text || '-'}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      rowKey: 'email',
      width: '23%',
      render: (text) => <span>{text || '-'}</span>,
    },
    {
      title: 'Documento',
      dataIndex: 'document',
      rowKey: 'document',
      width: '10%',
      render: (text) => <span>{text || '-'}</span>,
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      rowKey: 'phone',
      width: '15%',
      render: (text) => <span>{text || '-'}</span>,
    },
    {
      title: 'Condomínio',
      dataIndex: 'condominiumName',
      rowKey: 'condominiumName',
      width: '10%',
      render: (text) => {
        return <span>{text || '-'}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'active',
      rowKey: 'active',
      width: '10%',
      render: (text) => {
        return <span>{text ? 'Ativo' : 'Inativo' || '-'}</span>;
      },
    },
  ];

  if (['ADMIN', 'SUB_ADMIN'].includes(permission)) {
    columns.push(
      {
        title: 'Plano',
        dataIndex: 'plan',
        rowKey: 'plan',
        width: '10%',
        render: (text) => {
          return <span>{text?.planType || '-'}</span>;
        },
      },
      {
        title: 'Tipo',
        dataIndex: 'userType',
        rowKey: 'userType',
        width: '12%',
        render: (text) => {
          const type = USER_TYPE[text];
          return <span>{type || '-'}</span>;
        },
      },
      // {
      //   title: "Status",
      //   dataIndex: "active",
      //   rowKey: "active",
      //   width: "5%",
      //   render: (text) => <span>{text ? "Ativo" : "Inativo" || "-"}</span>,
      // },
    );
  }
  /**
   * @description - Por enquanto, proprietário não pode
   * @TODO change customer variable name
   */
  columns.push({
    title: 'Ações',
    dataIndex: 'view',
    rowKey: 'view',
    width: '10%',
    render: (text, customer) => {
      return (
        <div className="isoMainListBtnView">
          {/* Só administrador pode desativar usuário os outros só podem desvincular */}
          {['SUB_ADMIN', 'ADMIN'].includes(permission) ? (
            <>
              <Link to={`${matchParams.path}/${customer.id}`}>
                <Tooltip title="Editar">
                  <Button color="primary" className="mainListViewBtn">
                    <EditFilled />
                  </Button>
                </Tooltip>
              </Link>
              <Tooltip title={customer.active ? 'Desativar' : 'Reativar'}>
                <Button style={{ color: '#F00' }} onClick={() => onChangeStatus(customer)}>
                  {customer.active ? <DeleteFilled size={12} /> : <UserAddOutlined size={12} />}
                </Button>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title={'Desvincular'}>
                <Button style={{ color: '#F00' }} onClick={() => unlinkUser(customer)}>
                  <CloseOutlined size={12} />
                </Button>
              </Tooltip>
            </>
          )}
        </div>
      );
    },
  });

  return columns;
};
