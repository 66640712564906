const localDataName = 'mateInspection';
const orderStatusOptions = ['Pending', 'Shipped', 'Delivered'];

const fakedata = [
  {
    key: 1,
    name: 'Pineapple Inc.',
    type: 'Servico',
    phone: '1518713981655',
    email: 'email@example.com',
    document: '12.345.678/9012-34',
  },
  {
    key: 2,
    name: 'Apple Corp.',
    type: 'Produto',
    phone: '1518713981656',
    email: 'info@apple.com',
    document: '23.456.789/0123-45',
  },
  {
    key: 3,
    name: 'Banana Co.',
    type: 'Servico',
    phone: '1518713981657',
    email: 'contact@banana.co',
    document: '34.567.890/1234-56',
  },
  {
    key: 4,
    name: 'Orange Ltd.',
    type: 'Produto',
    phone: '1518713981658',
    email: 'support@orange.com',
    document: '45.678.901/2345-67',
  },
  {
    key: 5,
    name: 'Mango Enterprises',
    type: 'Servico',
    phone: '1518713981659',
    email: 'info@mangoenterprises.com',
    document: '56.789.012/3456-78',
  },
  {
    key: 6,
    name: 'Watermelon Co.',
    type: 'Produto',
    phone: '1518713981660',
    email: 'hello@watermelon.co',
    document: '67.890.123/4567-89',
  },
  {
    key: 7,
    name: 'Grapevine Inc.',
    type: 'Servico',
    phone: '1518713981661',
    email: 'info@grapevine.com',
    document: '78.901.234/5678-90',
  },
  {
    key: 8,
    name: 'Pear Corp.',
    type: 'Produto',
    phone: '1518713981662',
    email: 'contact@pearcorp.com',
    document: '89.012.345/6789-01',
  },
  {
    key: 9,
    name: 'Cherry Ltd.',
    type: 'Servico',
    phone: '1518713981663',
    email: 'support@cherry.com',
    document: '90.123.456/7890-12',
  },
  {
    key: 10,
    name: 'Pomegranate Co.',
    type: 'Produto',
    phone: '1518713981664',
    email: 'info@pomegranate.co',
    document: '01.234.567/8901-23',
  },
];

const newSupplier = {
  orderStatus: 'Pending',
  orderDate: new Date().getTime(),
  currency: '$',
  billTo: '',
  billToAddress: '',
  billFrom: '',
  billFromAddress: '',
  inspectionList: [
    {
      key: 1,
      itemName: '',
      costs: 0,
      qty: 0,
      price: 0,
    },
  ],
  subTotal: 0,
  vatRate: 10,
  vatPrice: 0,
  totalCost: 0,
};
const createDemoData = () => {
  const localData = localStorage.getItem(localDataName);
  if (localData) {
    try {
      const inspections = JSON.parse(localData);
      if (inspections && inspections.length > 0) {
        return inspections;
      }
    } catch (e) {}
  }
  return fakedata;
};

export { fakedata, createDemoData, localDataName, newSupplier, orderStatusOptions };
