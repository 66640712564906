const actions = {
  GET_SUPPLIER: 'GET_SUPPLIER',
  UPDATE_SUPPLIER: 'UPDATE_SUPPLIER',
  UPDATE_SUPPLIER_SAGA: 'UPDATE_SUPPLIER_SAGA',
  SELECT_CURRENT_SUPPLIER: 'SELECT_CURRENT_SUPPLIER',
  TOGGLE_VIEW: 'SUPPLIER_TOGGLE_VIEW',
  UPDATE_EDIT_SUPPLIER: 'SUPPLIER_UPDATE_EDIT_SUPPLIER',
  initData: () => ({ type: actions.GET_SUPPLIER }),
  deleteSupplier: (selected) => {
    return (dispatch, getState) => {
      const suppliers = getState().Suppliers.suppliers;
      const newSuppliers = [];
      suppliers.forEach((supplier) => {
        const selectedIndex = selected.indexOf(supplier.key);
        if (selectedIndex === -1) {
          newSuppliers.push(supplier);
        }
      });
      dispatch({
        type: actions.UPDATE_SUPPLIER_SAGA,
        suppliers: newSuppliers,
      });
    };
  },
  updateSupplier: (supplier) => {
    return (dispatch, getState) => {
      const suppliers = getState().Suppliers.suppliers;
      const index = suppliers.map((inv) => inv.id).indexOf(supplier.id);
      if (index === -1) {
        suppliers.push(supplier);
      } else {
        suppliers[index] = supplier;
      }
      dispatch({
        type: actions.UPDATE_SUPPLIER_SAGA,
        suppliers,
        supplier,
      });
    };
  },
  selectCurrentSupplier: (id) => ({ type: actions.SELECT_CURRENT_SUPPLIER, id }),
  toggleView: (view) => ({ type: actions.TOGGLE_VIEW, view }),
  editSupplier: (supplier) => ({ type: actions.UPDATE_EDIT_SUPPLIER, supplier }),
};
export default actions;
