import clone from 'clone';
import { newCustomer } from './config';
import actions from './actions';

const initState = {
  customers: [],
  initialCustomers: false,
  currentCustomer: {},
  editableCustomer: {},
  isNewCustomer: false,
  enableEditView: false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.UPDATE_CUSTOMER: {
      const currentCustomer = action.customer ? action.customer : state.currentCustomer;
      return {
        ...state,
        customers: action.customers,
        currentCustomer: clone(currentCustomer),
        initialCustomers: true,
        isNewCustomer: false,
        enableEditView: false,
      };
    }
    case actions.SELECT_CURRENT_CUSTOMER: {
      const customers = state.customers;
      const index = customers.map((customer) => customer.id).indexOf(action.id);
      const isNewCustomer = index === -1;
      const currentCustomer = isNewCustomer
        ? {
            id: action.id,
            number: `#${action.id}`,
            key: action.id,
            ...newCustomer,
          }
        : customers[index];
      const enableEditView = isNewCustomer;
      return {
        ...state,
        currentCustomer,
        isNewCustomer,
        enableEditView,
        editableCustomer: clone(currentCustomer),
      };
    }
    case actions.TOGGLE_VIEW:
      return {
        ...state,
        enableEditView: action.view,
        editableCustomer: clone(state.currentCustomer),
      };
    case actions.UPDATE_EDIT_CUSTOMER:
      return {
        ...state,
        editableCustomer: clone(action.customer),
      };
    default:
      return state;
  }
}
