import { all, takeEvery, put } from 'redux-saga/effects';
import { localDataName, createDemoData } from './config';
import actions from './actions';

export function* getCustomer() {
  yield put({
    type: actions.UPDATE_CUSTOMER,
    customers: createDemoData(),
  });
}
export function* updateInspectionSaga({ customers, inspection }) {
  yield localStorage.setItem(localDataName, JSON.stringify(customers));
  yield put({
    type: actions.UPDATE_CUSTOMER,
    customers,
    inspection,
  });
}
export default function* rootSaga() {
  yield all([yield takeEvery(actions.GET_CUSTOMER, getCustomer), yield takeEvery(actions.UPDATE_CUSTOMER_SAGA, updateInspectionSaga)]);
}
