import { all } from 'redux-saga/effects';
import authSaga from '../redux/auth/saga';
import supplierSaga from '../redux/supplier/saga';
import customerSaga from '../redux/customer/saga';
import ecommerceSaga from '../redux/ecommerce/saga';
import inspirationSaga from '../redux/inspiration/saga';

export default function* rootSaga(getState) {
  yield all([authSaga(), ecommerceSaga(), supplierSaga(), inspirationSaga(), customerSaga()]);
}
