import React from 'react';
import { toast } from 'react-toastify';
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      toast.error('Erro desconhecido. Contate o suporte.');
      return (window.location.href = '/dashboard');
    }

    // if (this.state.hasError) {
    //   return <p>Loading failed! Please reload.</p>;
    // }

    return this.props.children;
  }
}
