import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { AppProvider } from './AppProvider';
import { GlobalStyles } from './assets/styles/globalStyle';
import Boot from './redux/boot';
import { store } from './redux/store';
import { Routes } from './router';

const App = () => (
  <Provider store={store}>
    <AppProvider>
      <>
        <GlobalStyles />
        <Routes />
        <ToastContainer />
      </>
    </AppProvider>
  </Provider>
);

Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
