import antdEn from 'antd/lib/locale/pt_BR';
import ptBRMessages from '../locales/pt_BR.json';

const PtBrLang = {
  messages: {
    ...ptBRMessages,
  },
  antd: antdEn,
  locale: 'pt-BR',
};
export default PtBrLang;
