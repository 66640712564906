import { combineReducers } from 'redux';
import App from '../redux/app/reducer';
import Auth from '../redux/auth/reducer';
import ThemeSwitcher from '../redux/themeSwitcher/reducer';
import Ecommerce from '../redux/ecommerce/reducer';
import Inspiration from '../redux/inspiration/reducer';
import Customers from '../redux/customer/reducer';
import Suppliers from '../redux/supplier/reducer';
import LanguageSwitcher from '../redux/languageSwitcher/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  Ecommerce,
  LanguageSwitcher,
  Inspiration,
  Customers,
  Suppliers,
});
