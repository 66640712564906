import { all, takeEvery, put } from 'redux-saga/effects';
import { localDataName, createDemoData } from './config';
import actions from './actions';

export function* getInspection() {
  yield put({
    type: actions.UPDATE_SUPPLIER,
    suppliers: createDemoData(),
  });
}
export function* updateInspectionSaga({ suppliers, inspection }) {
  yield localStorage.setItem(localDataName, JSON.stringify(suppliers));
  yield put({
    type: actions.UPDATE_SUPPLIER,
    suppliers,
    inspection,
  });
}
export default function* rootSaga() {
  yield all([yield takeEvery(actions.GET_SUPPLIER, getInspection), yield takeEvery(actions.UPDATE_SUPPLIER_SAGA, updateInspectionSaga)]);
}
